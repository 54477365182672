import React from 'react';
import ScheduleCard from '../../components/ScheduleCard';
import StaticSlotList from '../../components/StaticSlotList';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/CSolver';
import { getColor } from '../../utils/ColorMapper';
import { generateNclSlotContent } from '../../utils/SlotContentFactory';
import { INVALID_SLOT_DELETE_MESSAGE, MAX_SLOT_ADD_MESSAGE, MAX_SLOT_WARNING, UNION_SCHEDULER_NAME } from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {MultiDisplayTable} from "../../components/MultiDisplayTable";

class UnionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/union_icon.png" />,
            slotList: () => <StaticSlotList contentFactory={generateNclSlotContent(this.state.time)} />,
            displayTable: () => <MultiDisplayTable/>
        };
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= 6) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max 6)`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }
    
    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (intervalsMaps.length > 6) {
            intervalsMaps = intervalsMaps.slice(0, 6);
            messages.push({severity: "warning", content: MAX_SLOT_WARNING});
        }

        let slotsInfo = this.slotsRef.current.getSlotsInfo();
        let {result, missing} = findSolution(timesMap, intervalsMaps, slotsInfo["lengthArray"], metadata["span"] * metadata["scale"], metadata["wrap"]);
        let solutionData = {};
        if (result === undefined) {
            return [{messages: [{severity: "error", content: "Could not find an arrangement for any slot"}]}];
        }
        for (let i = 0; i < result.length; i += 1) {
            for (let rowIndex in result[i]) {
                if (solutionData[rowIndex] === undefined) { 
                    solutionData[rowIndex] = [];
                }
                let array = solutionData[rowIndex];
                for (let interval of result[i][rowIndex]) {
                    array.push({interval: interval, borderColor: getColor(slotsInfo["colorArray"][i], 800), color: getColor(slotsInfo["colorArray"][i], 500), label: [slotsInfo["nameArray"][i]]})
                }
            }
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => slotsInfo["nameArray"][index]).join(", ")});
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    componentDidMount() {
        document.title = `${capitalize(UNION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(UnionSchedulerPage);