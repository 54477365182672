import React from "react";
import { doc, deleteDoc, addDoc, onSnapshot, updateDoc, query, orderBy } from "firebase/firestore";
import SlotList from './SlotList';
import {
    findInsertedOrder, findAppendedOrder
} from '../utils/OrderEncoderUtils'
import DynamicSlot from "./DynamicSlot";
import { defaultValues } from "../utils/DataUtils"
import { deepCompareArrays } from "../utils/ArrayUtils";
import {OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";

export default class DynamicSlotList extends SlotList {
    constructor(props) {
        super(props);
        this.Slot = <DynamicSlot rootPath={this.props.rootPath}/>;
        this.order = {};
        onSnapshot(query(this.props.rootPath, orderBy("order")), (querySnapshot) => {
            let orderToSlotIndex = [];
            querySnapshot.forEach((document) => {
                let data = document.data();
                defaultValues(data, {attributes: {color: "red", length: 5}});
                this.dataAttributes[document.id] = data.attributes;
                this.slotRefs[document.id] = this.slotRefs[document.id] || React.createRef();
                this.order[document.id] = data.order;
                orderToSlotIndex.push(document.id);
                //console.log(`${document.id} => ${JSON.stringify(data)}`);
            });

            let activeSlotId = this.state.activeSlotId || (orderToSlotIndex.length > 0 ? orderToSlotIndex[orderToSlotIndex.length - 1] : undefined);
            querySnapshot.forEach((document) => {
                let data = document.data();
                if (this.props.selected && activeSlotId === document.id) {
                    //this.props.propChangeAction({"tableColor": data.attributes.color});
                    let oldData = this.slotRefs[document.id].current ? this.slotRefs[document.id].current.getScheduleData()[0] : undefined;
                    if (data.scheduleData && data.scheduleData.data) {
                        let newData = JSON.parse(data.scheduleData.data);
                        if (!deepCompareArrays(oldData, newData)) {
                            this.props.dataChangeAction([newData, data.scheduleData.scheduleStart, data.scheduleData.scheduleEnd, data.scheduleData.timeFormat, data.scheduleData.selectAll]);
                        }
                    }
                }
            });
            
            this.setState({orderToSlotIndex: orderToSlotIndex, activeSlotId: activeSlotId}, () => {
                this.props.onLoadFinish();
            })
        }, () => {
			this.props.propChangeAction({"notFound": true});
		});
    }

    onDragEnd(result) {
        const { destination, source, } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        let orderToSlotIndex = this.state.orderToSlotIndex;
        let sourceKey = orderToSlotIndex.splice(source.index, 1)[0];

        let orders = orderToSlotIndex.map((key) => this.order[key]);
        let newOrder = findInsertedOrder(orders, destination.index);
        updateDoc(doc(this.props.rootPath, sourceKey), {
            order: newOrder
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });

        orderToSlotIndex.splice(destination.index, 0, sourceKey)
        this.setState({orderToSlotIndex: orderToSlotIndex})
    }

    deleteActiveSlot() {
        let activeSlotId = this.state.activeSlotId;
        deleteDoc(doc(this.props.rootPath, activeSlotId)).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
        let newSlotId = super.deleteActiveSlot();
        if (newSlotId === undefined) {
            return undefined;
        }
        return newSlotId;
    }

    addSlot(attributes) {
        let orders = this.state.orderToSlotIndex.map((key) => this.order[key]);
        let newSlotKey = findAppendedOrder(orders);

        if (this.state.orderToSlotIndex.length > 0) {
            defaultValues(attributes, this.slotRefs[this.state.orderToSlotIndex.slice(-1)].current.getSlotInfo());
        } else {
            defaultValues(attributes, {name: "Untitled Slot", color: this.props.color || "blue", length: this.props.timeFormat ? 30 : 1});
        }

        addDoc(this.props.rootPath, {
            attributes: attributes,
            order: newSlotKey
        }).then((docRef) => {
            this.switchSlot(docRef.id);
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
    }

    render() {
        if (this.props.hide) {
            return null;
        }
        return super.render();
    }
}