import React from 'react';
import ScheduleCard from '../../components/ScheduleCard';
import StaticSlotList from '../../components/StaticSlotList';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/ISolver';
import { getColor } from '../../utils/ColorMapper';
import {generateNcSlotContent} from '../../utils/SlotContentFactory';
import { INTERSECTION_SCHEDULER_NAME, INVALID_SLOT_DELETE_MESSAGE, MAX_SLOT_ADD_MESSAGE, MAX_SLOT_WARNING } from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import {MultiDisplayTable} from "../../components/MultiDisplayTable";
import {withErrorBoundary} from "../../components/ErrorBoundary";


class IntersectionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/intersection_icon.png"/>,
            slotList: () => <StaticSlotList contentFactory={generateNcSlotContent()} />,
            displayTable: () => <MultiDisplayTable/>
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= 6) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max 6)`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }
    
    // {0: [{interval: [a, b], color: {"blue[700]"}, label: {0, 1, 2}}, {interval: [c, d], color: {"blue[700]"}, label: {0, 1, 2}}], 5: [{interval: [e, f], color: {"blue[700]"}, label: {1, 2}}]}
    findSolution(timesMap, intervalsMaps,) {
        let messages = [];
        if (intervalsMaps.length > 6) {
            intervalsMaps = intervalsMaps.slice(0, 6);
            messages.push({severity: "warning", content: MAX_SLOT_WARNING});
        }

        let slotsInfo = this.slotsRef.current.getSlotsInfo();
        let solution = findSolution(timesMap, intervalsMaps);
        for (let rowIndex in solution) {
            for (let data of solution[rowIndex]) {
                let level = data.set.size / slotsInfo["nameArray"].length * 800 + 100;
                data.color = getColor("green", level);
                data.borderColor =  getColor("green", level + 100);
                data.label = Array.from(data.set).map(index => slotsInfo["nameArray"][index]);
                delete data["set"]
            }
        }
        return [{table: {solutionData: solution}, messages: messages}];
    }

    componentDidMount() {
        document.title = `${capitalize(INTERSECTION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(IntersectionSchedulerPage);