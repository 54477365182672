import React from "react";
import StaticSlotList from "./StaticSlotList";
import { generateNSlotContent } from "../utils/SlotContentFactory";
import ScheduleCard from "./ScheduleCard";
import {defaultSingleSlot} from "../initial_data";

export default class ScheduleCardList extends ScheduleCard {
	constructor(props) {
		super(props);
		this.slotListRef = React.createRef();
	}

	addSlot(attributes) {
		return this.slotListRef.current.addSlot(attributes);
	}

	getDefaultAttributesContent() {
		return this.slotListRef.current.getDefaultAttributesContent();
	}

	deleteActiveSlot() {
		return this.slotListRef.current.deleteActiveSlot();
	}

	getAuxContentList() {
		return [<StaticSlotList key={0} ref={this.slotListRef} data={defaultSingleSlot(this.props.color)} slotClickAction={this.props.slotClickAction} 
		propChangeAction={this.props.propChangeAction} dataChangeAction={this.props.dataChangeAction} timeFormat={this.props.timeFormat}
		selected={this.props.selected}  contentFactory={generateNSlotContent(this.props.timeFormat)} rotateColors={false}/>];
	}

	exportData(timeFormat) {
		return this.slotListRef.current.exportAllData(timeFormat);
	}

	getScheduleData() {
		return this.slotListRef.current.getScheduleData();
	}

	saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll) {
		this.slotListRef.current.saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll);
	}

	getSize() {
		return this.slotListRef.current.getSize();
	}

	getScheduleSlotsInfo() {
		return this.slotListRef.current.getSlotsInfo();
	}

	toString(startDate, timeFormat) {
		let title = `${this.state.title} (${this.props.startDate} - ${this.props.endDate})`;
		return `${title}\n\n${this.slotListRef.current.toString(startDate, timeFormat)}`;
	}
}