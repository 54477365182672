import React from "react";
import { MultiDisplayTable } from "./MultiDisplayTable";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {DisplayTabs} from "./DisplayTabs";
import TextField from "@mui/material/TextField";
import { getColor } from '../utils/ColorMapper';
import {deleteDoc, doc, onSnapshot, orderBy, query, updateDoc} from "firebase/firestore";
import Stack from "@mui/material/Stack";
import {findInsertedOrder} from "../utils/OrderEncoderUtils";
import EmptyTable from "../pages/EmptyTable";
import {OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";

export class DynamicTabbedMultiDisplayTable extends React.Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = {activeTabIndex: 0, solution: this.props.solution, title: this.props.solution[0] || ""};
        onSnapshot(query(this.props.rootPath, orderBy("order")), (snapshot) => {
            let solution = [];
            snapshot.forEach((doc) => {
                let data = doc.data();
                solution.push(data);
                if (data.metadata.start) {
                    data.metadata.start = data.metadata.start.toDate();
                }
                if (data.metadata.startDate) {
                    data.metadata.startDate = data.metadata.startDate.toDate();
                }
                data.id = doc.id;
            })
            this.setState({solution: solution, title: solution[this.state.activeTabIndex]?.title || ""});
        });
    }

    onDragEnd(result) {
        const { destination, source, } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        let solution = this.state.solution;
        let removedSolution = solution.splice(source.index, 1)[0];
        let orders = solution.map((sol) => sol.order);
        let newOrder = findInsertedOrder(orders, destination.index);
        solution.splice(destination.index, 0, removedSolution);
        updateDoc(doc(this.props.rootPath, removedSolution.id), {
            order: newOrder
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
        this.setState({solution: solution, activeTabIndex: destination.index});
    }

    setActiveIndex(index) {
        this.setState({activeTabIndex: index, title: this.state.solution[index].title});
    }

    deleteTab = (index) => {
        let solution = this.state.solution;
        let newActiveTabIndex = this.state.activeTabIndex;
        if (this.state.activeTabIndex > index || (this.state.activeTabIndex === index && index === solution.length - 1)) {
            newActiveTabIndex = Math.max(0, this.state.activeTabIndex - 1);
        }
        let removedSolution = solution.splice(index, 1)[0];
        deleteDoc(doc(this.props.rootPath, removedSolution.id));
        this.setState({solution: solution, activeTabIndex: newActiveTabIndex});
    }

    onChangeTitle = (event) => {
        this.setState({title: event.target.value});
    }

    onSaveTitle = (event) => {
        updateDoc(doc(this.props.rootPath, this.state.solution[this.state.activeTabIndex].id), {
            "title": event.target.value,
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
    }

    onTitleEnter = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }

    render() {
        if (this.state.solution === undefined || this.state.solution[this.state.activeTabIndex] === undefined) {
            return <EmptyTable/>;
        }
        return <React.Fragment>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <Stack ref={provided.innerRef} {...provided.droppableProps} direction="row" overflow="hidden" backgroundColor={getColor("blueGrey", 50)}>
                            {this.state.solution.map((sol, index) => {
                                return <React.Fragment key={index}>
                                    <DisplayTabs id={this.state.solution[index].id} width={100 / this.state.solution.length} index={index} name={sol.title} divider={this.state.activeTabIndex !== index && this.state.activeTabIndex !== index + 1}
                                         onPressAction={() => this.setActiveIndex(index)} deleteAction={() => this.deleteTab(index)} active={index === this.state.activeTabIndex}>
                                    </DisplayTabs>
                                </React.Fragment>
                            })}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
            <TextField sx={{boxSizing: "border-box", pt: 0.5, pl: 2, pr: 2}} value={this.state.title} id="outlined-basic" variant="standard"
               fullWidth InputProps={{disableUnderline: true, style: {fontWeight: 'bold', fontSize: "1.25rem"}}} onBlur={this.onSaveTitle}
                       onKeyDown={this.onTitleEnter} onChange={this.onChangeTitle} />
            <MultiDisplayTable key={this.state.solution[this.state.activeTabIndex].id} solution={this.state.solution[this.state.activeTabIndex]} />
        </React.Fragment>
    }
}