import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getColor } from '../utils/ColorMapper';
import TimeSwitch from './TimeSwitch';
import Avatar from '@mui/material/Avatar';
import { print } from "../utils/DataUtils";
import { getDaysDifference } from "../utils/DateTimeUtils";

const timeIntervalsToTime = {"10 minutes": 10, "15 minutes": 15, "20 minutes": 20, "30 minutes": 30, "1 hour": 60, "2 hours": 120, "3 hours": 180};
const dateSpansToDays = {"1 week": 1, "2 weeks": 2, "4 weeks": 4 }; 
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const tableTypeMap = {"Selection": true, "Checkbox": false};


export default class ScheduleCard extends React.PureComponent {
	constructor(props) {
		super(props);
		this.saveState = this.saveState.bind(this);
		this.saveDataToActive = this.saveDataToActive.bind(this);
		this.state = ({expanded: false, title: "Untitled schedule"});
	}

	handleChange(field) {
		return (event) => {
			let value = event.target.value;
			switch (field) {
				case "startTime": {
					const endTime = this.state.endTime || this.props.endTime;
					if (endTime === undefined || endTime > value) {
						this.saveState(field, value);
						this.saveState("endTime", endTime);
						this.setState({[field]: undefined, "endTime": undefined});
						
					} else {
						this.setState({[field]: value});
					}
					break;
				}
				case "endTime": {
					const startTime = this.state.startTime || this.props.startTime;
					if (startTime === undefined || startTime < value)  {
						this.saveState(field, value);
						this.saveState("startTime", startTime);
						this.setState({[field]: undefined, "startTime": undefined});
					} else {
						this.setState({[field]: value});
					}
					break;
				}
				case "startDate": {
					const endDate = this.state.endDate || this.props.endDate;
					if (endDate === undefined || endDate >= value && getDaysDifference(value, endDate) <= 365 * 10) {
						this.saveState(field, value);
						this.saveState("endDate", endDate);
						this.setState({[field]: undefined, "endDate": undefined});
					} else {
						this.setState({[field]: value});
					}
					break;
				}
				case "endDate": {
					const startDate = this.state.startDate || this.props.startDate;
					if (startDate === undefined || startDate <= value && getDaysDifference(startDate, value) <= 365 * 10) {
						this.saveState(field, value);
						this.saveState("startDate", startDate);
						this.setState({[field]: undefined, "startDate": undefined});
					} else {
						this.setState({[field]: value});
					}
					break;
				}
				case "time":
					this.saveState(field, !this.props.time);
					break;
				case "expand":
					this.setState({expanded: !this.state["expanded"]});
					break;
				case "title":
					this.setState({[field]: value});
					break;
				default:
					this.saveState(field, value);
			}
		}
	}

	saveState(field, value) {
		this.props.tableChangeAction({[field]: value});
	}

	handleClick = () => {
		if (!this.props.selected) {
			this.props.slotClickAction({color: this.props.color, data: this.getScheduleData()})
		}
	}

	getAuxContentList() {
		return [];
	}

	render() {
		return <Card className="menuItem" sx={{border: 1, borderColor: getColor(this.props.color, 500), '@media (max-width:599px)': {minHeight:`${this.props.height - 2}px`}}} onClick={this.handleClick} style={{backgroundColor: this.props.selected ? getColor(this.props.color, 200) : null}}>
			<CardHeader
			avatar={<Avatar sx={{width: 20, height: 20 }} variant="rounded" src={this.props.icon} aria-label="schedule icon"/>}
			action={
				<IconButton aria-label="settings" onClick={this.handleChange("expand")}>
					<SettingsIcon />
				</IconButton>
			}
			sx={{
				overflow: "hidden",
				"& .MuiCardHeader-content": {
				overflow: "hidden"
				}
			}}
			title={this.state.title}
			titleTypographyProps={{ noWrap: true, variant:'h7' }}
			subheader={(this.props.startDate || this.props.endDate) ? [this.props.startDate, this.props.endDate].join(" — ") : null}
			subheaderTypographyProps={{ noWrap: true }}
			/>
			<Collapse in={this.state["expanded"]} timeout="auto" >
				<CardContent>
					<Box display="flex" flexWrap="wrap" gap={1}>
						<TextField id="outlined-basic" style={{flexGrow: 2.6, flexBasis: "20ch"}} label="Title" variant="outlined" value={this.state.title || ''} onChange={this.handleChange("title")}/>
						<TimeSwitch sx={{mt: 1}} onChange={this.handleChange("time")} checked={!this.props.time || false} />
						<TextField key="start-time-input" id="start-time-input" style={{flexGrow: 1, flexBasis: "12ch"}} sx={{display: this.props.time ? null : 'none' }} label="Start Time" variant="outlined" type= "time" value={this.state.startTime || this.props.startTime} onChange={this.handleChange("startTime")}/>
						<TextField key="end-time-input" id="end-time-input" style={{flexGrow: 1, flexBasis: "12ch"}} sx={{display: this.props.time ? null : 'none' }} label="End Time" variant="outlined" type="time" value={this.state.endTime || this.props.endTime} onChange={this.handleChange("endTime")}/>
						<TextField key="start-day-select" id="start-day-select" style={{flexGrow: 1, flexBasis: "12ch"}} sx={{display: this.props.time ? 'none' : null }} select label="Start Day" value={this.props.startDay} onChange={this.handleChange("startDay")}>
							{days.map((option) => (
								<MenuItem key={option} value={days.indexOf(option)}>
									{option}
								</MenuItem>
							))}
						</TextField>
						<TextField key="end-day-select" id="end-day-select" style={{flexGrow: 1, flexBasis: "12ch"}} sx={{display: this.props.time ? 'none' : null }} select label="End Day" value={this.props.endDay} onChange={this.handleChange("endDay")}>
							{days.map((option) => (
								<MenuItem key={option} value={days.indexOf(option)}>
									{option}
								</MenuItem>
							))}
						</TextField>
						<TextField key="start-date-input" id="start-date-input" style={{flexGrow: 1, flexBasis: "12ch"}} label="Start Date" variant="outlined" type= "date" value={this.state.startDate || this.props.startDate} onChange={this.handleChange("startDate")} InputProps={{inputProps: {max:"9999-12-31"}}}/>
						<TextField key="end-date-input" id="end-date-input" style={{flexGrow: 1, flexBasis: "12ch"}} label="End Date" variant="outlined" type="date" value={this.state.endDate ||this.props.endDate} onChange={this.handleChange("endDate")} InputProps={{inputProps: {max:"9999-12-31"}}}/>
						<TextField key="time-select" id="time-select" style={{flexGrow: 1.6, flexBasis: "14ch"}} sx={{display: this.props.time ? null : 'none' }} select label="Time Interval" value={this.props.interval} onChange={this.handleChange("interval")}>
							{[...Object.keys(timeIntervalsToTime)].map((option) => (
								<MenuItem key={option} value={timeIntervalsToTime[option]}>
									{option}
								</MenuItem>
							))}
						</TextField>
						<TextField key="day-select" id="day-select" style={{flexGrow: 1.6, flexBasis: "14ch"}} sx={{display: this.props.time ? 'none' : null }} select label="Day Spans" value={this.props.span} onChange={this.handleChange("span")}>
							{[...Object.keys(dateSpansToDays)].map((option) => (
								<MenuItem key={option} value={dateSpansToDays[option]}>
									{option}
								</MenuItem>
							))}
						</TextField>
						<TextField key="table-select" id="table-select" style={{flexGrow: 1.6, flexBasis: "14ch"}} select label="Input type" 
							value={this.props.complexTableType} onChange={this.handleChange("complexTableType")} aria-label="input type selector">
							{[...Object.keys(tableTypeMap)].map((option) => (
								<MenuItem key={option} value={tableTypeMap[option]}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Box>
					{this.getAuxContentList().map((content, index) => (
						<React.Fragment key={index}>
							{content}
						</React.Fragment>
					))}
				</CardContent>
			</Collapse>
		</Card>
	}

	exportData(timeFormat) {
		let data = []
		if (this.scheduleData !== undefined && timeFormat === this.scheduleData.timeFormat) {
			data = this.scheduleData.data
		}
		return [data, this.scheduleData.scheduleStart, this.scheduleData.scheduleEnd, this.scheduleData.selectAll];
	}
	
	getScheduleData() {
		if (this.scheduleData === undefined) {
			return [undefined, undefined, undefined, undefined, false];
		}
		return [this.scheduleData.data, this.scheduleData.scheduleStart, this.scheduleData.scheduleEnd, this.scheduleData.timeFormat, this.scheduleData.selectAll]
	}

	deleteActiveSlot() {
		throw new Error("Not a supported function.");
	}

	saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll) {
		this.scheduleData = {data: data, timeFormat: timeFormat, scheduleStart: scheduleStart, scheduleEnd: scheduleEnd, selectAll: selectAll};
	}

	saveSolution(solution) {
		this.solution = solution;
	}

	getSolution() {
		return this.solution;
	}

	toString(startDate, timeFormat) {
		let [data, scheduleStart, scheduleEnd, scheduleTimeFormat, selectAll] = this.getScheduleData();
		let title = `${this.state.title} (${this.props.startDate} - ${this.props.endDate})`;
		return print(title, data, scheduleStart, scheduleEnd, selectAll, scheduleTimeFormat, startDate, timeFormat);
	}
}