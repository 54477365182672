import React from 'react';
import DynamicScheduleCard from '../../components/DynamicScheduleCard';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import { generateNclSlotContent } from '../../utils/SlotContentFactory';
import { ClickTable } from '../../components/ClickTable';
import { CheckTable } from '../../components/CheckTable';
import { TouchTable } from '../../components/TouchTable';
import { MultiCheckTable } from '../../components/MultiCheckTable';
import { MultiClickTable } from '../../components/MultiClickTable';
import { capitalize } from '../../utils/StringUtils';
import {
    FULFILLMENT_SCHEDULER_NAME,
    INVALID_SLOT_DELETE_MESSAGE,
    MAX_SLOT_ADD_MESSAGE,
    SLOTS_PER_PLAN
} from '../../utils/Constants';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";
import {getColor} from "../../utils/ColorMapper";


class DynamicFulfillmentSchedulerPage extends DynamicSchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCard key="scheduleCard" icon="/fulfillment_logo.svg"  {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNclSlotContent(this.state.time)} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" {...this.displayProps()} />
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            let limit = SLOTS_PER_PLAN[this.props.id][this.state.planName];
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= limit) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    getTableType() {
        if (window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/i)) {
            return this.state.complexTableType ? TouchTable : CheckTable;
        } else {
            if (this.state.scheduleActive) {
                return this.state.complexTableType ? MultiClickTable : MultiCheckTable;
            }
            return this.state.complexTableType ? ClickTable : CheckTable;
        }
    }

    async findSolution(timesMap, intervalsMaps) {
        let slotsInfo = this.slotsRef.current.getSlotsInfo();
        let slots = intervalsMaps.map((intervalsMap, index) => { return {"rows": intervalsMap, "length": slotsInfo["lengthArray"][index]}; });
        let input = {"schedule": {"rows": timesMap}, "slots": slots};
        //console.log(timesMap, intervalsMaps);
        let response = await this.callSolver("fulfillment", input);
        //console.log("response", response);
        if (response === undefined) {
            return [{messages: [{severity: "error", content: "An error occurred. Please try again"}]}];
        }
        let messages = [];
        if (response.status?.result === "FAILURE") {
            messages.push({severity: "error", content: "Could not find an arrangement for any slot"});
        } else if (response.status?.result === "PARTIAL_SUCCESS") {
            messages.push({severity: "warning", content: `Could not find an arrangement for 
                ${response.status.messageData?.failureIndices?.map((index) => slotsInfo["nameArray"][index]).join(", ")}`});
        }
        let tables = response.schedule.slice(1).map((schedule, index) => {
            let solutionData = {};
            for (let rowIndex of Object.keys(schedule.table)) {
                solutionData[rowIndex] = schedule.table[rowIndex].map((entry) => {
                    return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 800), "color": getColor(slotsInfo["colorArray"][index], 500), "label": [slotsInfo["nameArray"][index]]};
                });
            }
            return {solutionData: solutionData, title: `${slotsInfo.nameArray[index]}`};
        });

        let summarySolutionData = {};
        Object.entries(response.schedule[0].table).forEach(([rowIndex, row]) => {
            summarySolutionData[rowIndex] = row.map((entry) => {
                let magnitude = entry.data.occupied;
                let capacity = entry.data.capacity;
                return {interval: [entry.start, entry.end], borderColor: getColor("green", magnitude / capacity * 1000 + 500), color: getColor("green", magnitude / capacity * 1000 + 300), label: [`${magnitude} of ${capacity} slots filled`]};
            });
        });
        tables.unshift({solutionData: summarySolutionData, title: "Overview"});

        return tables.map((table) => { return{messages: messages, table: table}});
    }

    componentDidMount() {
        document.title = `${capitalize(FULFILLMENT_SCHEDULER_NAME)} scheduler | Snug Scheduler`;
    }
}

export default withErrorBoundary(DynamicFulfillmentSchedulerPage);