import React from "react";
import Event from "./Event"
import { Table } from "./Table";
import Tooltip from '@mui/material/Tooltip';
import {toTimeString, toDateString, addField} from "../utils/DateTimeUtils";
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

export class DisplayTable extends Table {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    gcd = function(a, b) {
        if (!b) {
            return a;
        }
        return this.gcd(b, a % b);
    }

    render() {
        let {messages, solutionData} = this.props.data;
        let factor = this.props.scale;
        for (let rowIndex in solutionData) {
            let row = solutionData[rowIndex];
            for (let j = 0; j < row.length; j += 1) {
                let interval = row[j]["interval"];
                factor = this.gcd(factor, interval[0]);
                factor = this.gcd(factor, interval[1]);
            }
        }
        let minFactor = Math.ceil(this.props.scale * this.props.tableCols.length / 1000);
        factor = Math.max(factor, minFactor);

        let rows = [];
        for (let rowIndex in solutionData) {
            if (rows[rowIndex] === undefined) {
                rows[rowIndex] = [];
            }

            for (let j = 0; j < solutionData[rowIndex].length; j += 1) {
                let interval = solutionData[rowIndex][j]["interval"];
                let color = solutionData[rowIndex][j]["color"];
                let borderColor = solutionData[rowIndex][j]["borderColor"];
                let label = solutionData[rowIndex][j]["label"];
                let startString;
                let endString;
                let start = new Date(this.props.start.getTime());
                let end = new Date(this.props.start.getTime());
                addField(rowIndex * this.props.range + interval[0], this.props.interval, start);
                addField(rowIndex * this.props.range + interval[1], this.props.interval, end);
                if (this.props.timeFormat) {
                    startString = toTimeString(start, "L");
                    endString = toTimeString(end, "L");
                } else {
                    startString = toDateString(start, "L");
                    endString = toDateString(end, "L");
                }
                rows[rowIndex].push(new Event({color: color, borderColor: borderColor, label: label, start: startString, end: endString}, 
                    Math.floor(interval[0] / factor), Math.floor(interval[1] / factor)));
            }
        }

        for (var i = 0; i < this.props.tableRows.length; i += 1) {
            if (rows[i] === undefined) {
                rows[i] = [];
            } else {
                rows[i].sort((a, b) => {
                    return a.start - b.end;
                })
            }
        }

        let hover = undefined;
        if (messages !== undefined && messages.length > 0) {
            let icon;
            switch(messages[0].severity) {
                case("warning"):
                    icon = <WarningIcon sx={{color: "warning.main"}}/>
                    break;
                case("error"):
                    icon = <ErrorIcon sx={{color: "error.main"}}/>
                    break;
                default:
                    icon = <InfoIcon sx={{color: "info.main"}}/>
                    break;
            }
            hover = <Tooltip arrow title={messages[0].content}>{icon}</Tooltip>
        }

        let unit = this.props.scale / factor;
        let table = this.convertToTable(rows, unit);
        return <div data-testid="displayTable">
            <table className="selection" ref={this.tableRef} width={"100%"}>
                { this.constructTableHeader(unit, hover) }
                <tbody>
                    { table.map((tableRow, i) => {
                        return <tr className="selection" key={i} height={this.props.height} colSpan={unit * (this.props.tableCols.length + 1)}>
                            { this.constructTableRowHeader(i, unit) }
                            { tableRow.filter(([, length]) => length > 0).map(([id, length], j) => {
                                if (id !== undefined) {
                                    return <Tooltip key={j} arrow title={
                                        <div>
                                            {id.label.map((label, index) => (
                                                <div key={index}>{label}</div>
                                            ))}
                                            <div>{id.start} - {id.end}</div>
                                        </div>
                                    }>
                                        <td className="selection" style={{border: `1px double ${id.borderColor}`, background: id.color}} colSpan={length} ></td>
                                    </Tooltip>
                                } else {
                                    return <td className="selection" key={j} colSpan={length} ></td>
                                }
                                })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }
}