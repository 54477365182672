import React from "react";
import { getColor } from '../utils/ColorMapper'
import { ActionTable } from "./ActionTable";
export class MultiCheckTable extends ActionTable {
	constructor(props) {
		super(props);
		this.arr = this.getInternalArr(this.props.data, true);
		this.state = {data: this.arr};
	}

	setSlot(event) {
		if (this.lastCol === undefined || this.lastRow === undefined) {
			return;
		}
		let row = this.getIndexFromX(this.getMouseX(event));
		let col = this.getIndexFromY(this.getMouseY(event));

		if (col >= 0 && col < this.arr.length && row >= 0 && row < this.arr[col].length && (col !== this.lastCol || row !== this.lastRow)) {
			this.arr[col][row] = (this.arr[col][row] + 1) % 6;
			this.lastCol = col;
			this.lastRow = row;
			this.setState({data: this.arr});
		}
	}

	onTableDrag(event) {
		this.setSlot(event)
	}

	onTableRelease() {
		this.lastCol = undefined;
		this.lastRow = undefined;
	}

	initFn = (event) => {
		let row = this.getIndexFromX(this.getMouseX(event));
		let col = this.getIndexFromY(this.getMouseY(event));
		this.arr[col][row] = (this.arr[col][row] + 1) % 6;
		this.lastCol = col;
		this.lastRow = row;
		this.setSlot(event);
	}

	tableActions() {
		return {onMouseDown: this.onRightMouseDown(this.initFn, this.onTableDrag, this.onTableRelease, this.onTableRelease), onMouseMove: this.onMouseMove, onMouseUp: this.onRightMouseUp };
	}

	render() {
		let table;
		if (this.state.selectAll) {
			table = <table className="selection" ref={this.tableRef} width={"100%"}>
				{ this.constructTableHeader() }
				{this.props.tableRows.map((row, i) => {
					return <tr className="selection" key={i} height={this.props.height}>
						{ this.constructTableRowHeader(i) }
						{this.props.tableCols.map((_, j) => {
							return <td className="selection" key={j} style={{background: getColor(this.props.color, 200)}}></td>
						})}
					</tr>
				})}
			</table>
		} else {
			table = <table className="selection" ref={this.tableRef} width={"100%"} {...this.tableActions()} >
				{ this.constructTableHeader() }
				{this.state.data.map((tableRow, i) => {
					return <tr className="selection" key={i} height={this.props.height}>
						{ this.constructTableRowHeader(i) }
						{tableRow.map((magnitude, j) => {
							return <td className="selection" key={j} style={magnitude ? {background: getColor(this.props.color, magnitude * 100)} : undefined}>
								{magnitude !== 0 ? magnitude : undefined}
							</td>
						})}
					</tr>
				})}
			</table>
		}
		return super.render(table);
	}

	returnData = () => {
		let data = [];
		for (var i = 0; i < this.arr.length; i += 1) {
			let row = [];
			let start = undefined;
			let magnitude = undefined;
			for (var j = 0; j < this.arr[i].length; j += 1) {
				if (!this.arr[i][j] || magnitude !== this.arr[i][j]) {
					if (start !== undefined) {
						row.push([start, j, magnitude]);
						start = undefined;
					}
				}
				if (this.arr[i][j]) {
					if (start === undefined) {
						start = j;
						magnitude = this.arr[i][j];
					}
				}
			}
			if (start !== undefined) {
				row.push([start, this.arr[i].length]);
			}
			data.push(row);
		}
		return [data, this.state.selectAll, this.state.clipboard];
	}

	getInternalArr(data, retain) {
		if (data === undefined || !retain) {
			data = [...Array(this.props.tableRows.length)].map(() => []);
		}
		let arr = new Array(this.props.tableRows.length)
		for (let i = 0; i < arr.length; i += 1) {
			arr[i] = new Array(this.props.tableCols.length).fill(0);
			if (data[i] === undefined) {
				continue;
			}
			for (let j = 0; j < data[i].length; j += 1) {
				for (let k = Math.max(data[i][j][0], 0); k < Math.min(data[i][j][1], this.props.tableCols.length); k += 1) {
					arr[i][k] = data[i][j][2];
				}
			}
		}
		return arr;
	}

	loadData = (data, selectAll, retain, clipboard) => {
		this.arr = this.getInternalArr(data, retain);
		this.setState({data: this.arr, selectAll: selectAll, clipboard: clipboard})
	}
}