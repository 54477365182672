/**
 * Deep compares two arrays of integers.
 * @param {number[]} arr1 - The first array.
 * @param {number[]} arr2 - The second array.
 * @returns {boolean} - True if the arrays are deep equal, false otherwise.
 */
export function deepCompareArrays(arr1, arr2) {
    //Initial case, arrays may be undefined
    if (arr1 === undefined || arr2 === undefined) {
        return false;
    }

    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Iterate over each element in the arrays
    for (let i = 0; i < arr1.length; i++) {
        const element1 = arr1[i];
        const element2 = arr2[i];

        // If the elements are arrays, recursively compare them
        if (Array.isArray(element1) && Array.isArray(element2)) {
            if (!deepCompareArrays(element1, element2)) {
                return false;
            }
        }
        // If the elements are not arrays, compare them directly
        else if (element1 !== element2) {
            return false;
        }
    }

    // All elements are equal
    return true;
}
