import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import {getColor} from '../utils/ColorMapper'
import {
	addDoc,
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	updateDoc,
	where
} from "firebase/firestore";
import {db} from "../firebase"
import Button from '@mui/material/Button';
import {withAuth} from "./AuthContext"
import WordArray from 'crypto-js/lib-typedarrays';
import {bs60, convert_word_array_to_uint8Array} from '../utils/HashUtils'
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import SelectChip from '../components/SelectChip';
import {useSearchParams} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import {log} from '../utils/AnalyticsUtils';
import Snackbar from "@mui/material/Snackbar";
import {WRITE_FAILED_DETAIL} from "../utils/Constants";

const scheduleNames = ["Separate time scheduler", "Common time scheduler", "Selection scheduler", "Distribution scheduler", "Fulfillment scheduler"];
const scheduleTypes = ["union", "intersection", "selection", "distribution", "fulfillment"];
const scheduleIcon = ["union_icon.png", "/intersection_icon.png", "/selection_icon.png", "/distribution_icon.png", "/fulfillment_logo.svg"];
const scheduleLogo = ["separation.png", "intersection.png", "selection.png", "distribution.png", "fulfillment.png"];
const sortNames = ["Most recently modified", "Least recently modified", "Name ascending (A-Z)", "Name descending (Z-A)"]
const sortValues = ["mod_desc", "mod_asc", "name_asc", "name_desc"]

											
const MySchedules = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [dataMap, setDataMap] = useState({});
	const [open, setOpen] = useState(false);
	const [type, setType] = useState(scheduleTypes.includes(searchParams.get("type")) ? searchParams.get("type") : undefined);
	const [sort, setSort] = useState(sortValues.includes(searchParams.get("sort")) ? searchParams.get("sort") : undefined);
	const [currentlyLoading, setCurrentlyLoading] = useState(true);
	const anchorRef = useRef(null);
	const [cardActionAnchorEl, setCardActionAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = React.useState(undefined);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarActionText, setSnackbarActionText] = useState("");
	const [snackbarAction, setSnackbarAction] = useState(()=> () => {});

	useEffect(() => {
		setCurrentlyLoading(true);
		log("schedule_query", {metadata: [type, sort]});
		let queryComponents = [collection(db, "schedules"),  where('uid', '==', props.auth.currentUser.uid), where("archived", "==", false)]
		if (type) {
			queryComponents.push(where('type', '==', type))
		}
		switch (sort) {
			case "mod_desc":
				queryComponents.push(orderBy("updatedAt", "desc"));
				break;
			case "mod_asc":
				queryComponents.push(orderBy("updatedAt", "asc"));
				break;
			case "name_asc":
				queryComponents.push(orderBy("name", "asc"));
				break;
			case "name_desc":
				queryComponents.push(orderBy("name", "desc"));
				break;
			default:
				break;
		}
        const unsubscribe = onSnapshot(query(...queryComponents), (querySnapshot) => {
            let dataMap = {};
            querySnapshot.forEach((doc) => {
				//console.log(`${doc.id} => ${JSON.stringify(data)}`);
                dataMap[doc.id] = doc.data();
            });
            setDataMap(dataMap);
            setCurrentlyLoading(false);
        });

        // Cleanup function
        return () => unsubscribe();
    }, [props.auth.currentUser.uid, type, sort]);

	useEffect(() => {
		let params = {};
		if (type) {
			params["type"] = type;
		}
		if (sort) {
			params["sort"] = sort;
		}
		setSearchParams(params);
	}, [type, sort]);

	useEffect(() => {
		document.title = "My Schedules";
	});


	const handleToggle = () => {
		log("button", {name: "New schedule"})
		setOpen(!open);
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	}

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	const handleCardActionClick = (event, id) => {
		event.stopPropagation();
		setCardActionAnchorEl(event.currentTarget);
		setMenuId(id);

	};

	const handleDelete = () => {
		updateDoc(doc(db, "schedules", menuId), {
			archived: true
		}).then(() => {
			showMessage("Schedule deleted.", "undo", () => {
				updateDoc(doc(db, "schedules", menuId), {
					archived: false
				}).then(() => {
					showMessage("Schedule restored.");
				});
			});
		}).catch(() => {
			showMessage(`Unable to delete schedule. ${WRITE_FAILED_DETAIL}`);
		});
		setCardActionAnchorEl(null);
		setMenuId(undefined);
	};

	const showMessage = (message, actionText, action) => {
		setSnackbarMessage(message);
		setSnackbarOpen(true);
		setSnackbarActionText(actionText || "");
		setSnackbarAction(() => (action || (() => {})));
	};

	const snackbarActionButton = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={snackbarAction}>
				{snackbarActionText}
			</Button>
		</React.Fragment>
	);

	const onCardOpen = function(scheduleId) {
		return () => {
			updateDoc(doc(db, "schedules", scheduleId), {
				updatedAt: serverTimestamp()
			});
			window.open(`/scheduler/${scheduleId}`);
		}
	}

	const add = (type) => {
		return () => {
			let sharing = {
				key: bs60.encode(convert_word_array_to_uint8Array(WordArray.random(20))),
				enabled: false
			}
			let currentTime = serverTimestamp();
			addDoc(collection(db, "schedules"), {
				name: "Untitled Schedule (" + type + ")",
				type: type,
				archived: false,
				uid: props.auth.currentUser.uid,
				sharing: sharing,
				createdAt: currentTime,
				updatedAt: currentTime
			}).then(function(docRef) {
				setDoc(doc(db, "schedules", docRef.id, sharing.key, "DEFAULT"), {
					
				}).then(() => {
					window.open(`/scheduler/${docRef.id}`);
				});
			}).catch(function() {
				showMessage(`Unable to create schedule. ${WRITE_FAILED_DETAIL}`)
			});
			setOpen(false);
		}
	}

	return <Container maxWidth="xl">
		<h1>My Schedules</h1>
		<Stack direction={{xs:'column', sm:'row'}}  spacing={2} p={2} justifyContent="space-between">
			<Box component="section">
				<Button size="large" variant="contained" id="composition-button" ref={anchorRef} onClick={handleToggle} >New schedule</Button>
				<Popper sx={{zIndex: 3}} open={open} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
					{...TransitionProps}
					style={{
						transformOrigin:
						placement === 'bottom-start' ? 'left top' : 'left bottom',
					}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
								>
									{scheduleTypes.map((scheduleType, index) => {
										return <MenuItem key={index} onClick={add(scheduleType)}>
											<ListItemIcon>
												<Avatar alt="" src={scheduleIcon[index]} variant="rounded" sx={{ width: 24, height: 24, mr: 2 }} />
											</ListItemIcon>
											<ListItemText>{scheduleNames[index]}</ListItemText>
										</MenuItem>
										})
									}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
				</Popper>
			</Box>
			<Box component="section" >
				<SelectChip label="Type" optionKeys={scheduleNames} optionValues={scheduleTypes} value={type} setValue={setType}/>
				<SelectChip label="Sort" optionKeys={sortNames} optionValues={sortValues} value={sort} setValue={setSort}/>
			</Box>
		</Stack>

		<Menu keepMounted anchorEl={cardActionAnchorEl} open={menuId !== undefined} onClose={() => setMenuId(undefined)} >
			<MenuList>
				<MenuItem onClick={handleDelete}>
					<ListItemIcon>
						<DeleteIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</MenuList>
		</Menu>

		{currentlyLoading && <Box sx={{ display: 'flex', left: 0, position: "absolute", width: "100%", justifyContent: "center"}}><CircularProgress sx={{zIndex: 1, m: 2}}/></Box>}
		{!currentlyLoading && Object.keys(dataMap).length === 0 ? 
		<Typography m={5} align="center" variant="h6" color="grey">No schedules found</Typography> :
		<Grid sx={{ opacity: currentlyLoading ? 0.5 : 1 }} container spacing={{ xs: 2, md: 3 }} mt={3} >
			{Object.entries(dataMap).map(([id, data], index) => {
				return <Grid key={index} xs={12} sm={6} md={4} lg={3}>
					<Card  style={{backgroundColor: getColor("grey", 100)}} sx={{ borderRadius: '16px' }} elevation={0} >
						<CardHeader
							avatar={<Avatar sx={{width: 20, height: 20 }} variant="rounded" src={scheduleIcon[scheduleTypes.indexOf(data.type)]} aria-label="schedule icon"/>}
							action={<IconButton aria-label="settings" onClick={(event) => handleCardActionClick(event, id)}>
								<MoreVertIcon/>
							</IconButton>}
							title={data.name}
						/>
						<CardActionArea sx={{p: 2, pb: 1}} onClick={(onCardOpen(id))}>

							<CardMedia
								component="img"
								sx={{ border: 1, borderColor: 'grey.300', borderRadius: '16px', height: 250, objectFit: "contain", p: 1, boxSizing: "border-box"}}
								image={`${process.env.PUBLIC_URL}/${scheduleLogo[scheduleTypes.indexOf(data.type)]}`}
								alt="Diagram"
							/>
							<CardContent>
								<Typography noWrap color="grey" variant="subtitle2">
									Last modified • {new Date(data?.updatedAt?.seconds * 1000).toDateString()}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			})}
		</Grid>}
		<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}
			message={snackbarMessage} action={snackbarActionButton}/>
	</Container>
}

export default withAuth(MySchedules);