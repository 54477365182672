import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Paper, Typography } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import CardMedia from '@mui/material/CardMedia';
import Carousel from 'react-material-ui-carousel'
import { Grid } from '@mui/material';
import { getColor } from '../utils/ColorMapper';
import {
    APPOINTMENT_HOOK,
    APP_TITLE,
    CLASS_HOOK,
    FILL_STEP,
    FILL_STEP_DESCRIPTION,
    FIND_STEP,
    FIND_STEP_DESCRIPTION,
    INPUT_PERK_DESCRIPTION,
    INPUT_PERK_TITLE,
    LINK_PERK_DESCRIPTION,
    LINK_PERK_TITLE,
    MAIN_BUTTON_TEXT,
    MAIN_SUBTITLE,
    MAIN_TITLE,
    MEETING_HOOK,
    ONE_ON_ONE_HOOK,
    SECONDARY_BUTTON_TEXT,
    SELECT_STEP,
    SELECT_STEP_DESCRIPTION,
    SHIFT_HOOK,
    SOLVER_PERK_DESCRIPTION,
    SOLVER_PERK_TITLE,
    STEP_TITLE,
    TASK_HOOK,
    BUTTON_SUBTITLE
} from '../utils/Constants';
import { log } from '../utils/AnalyticsUtils';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const scheduleTypes = ["Appointment", "Class", "Shift", "Task", "Meeting", "One-on-one"];
const icons = [EventAvailableIcon, SchoolOutlinedIcon, WorkOutlineOutlinedIcon, BuildRoundedIcon, GroupsIcon, PersonIcon]
const descriptions = [APPOINTMENT_HOOK, CLASS_HOOK, SHIFT_HOOK, TASK_HOOK, MEETING_HOOK, ONE_ON_ONE_HOOK]

const steps = [SELECT_STEP, FILL_STEP, FIND_STEP];
const stepImages = ["select.png", "fill.png", "solve.png"];
const stepDescriptions = [SELECT_STEP_DESCRIPTION, FILL_STEP_DESCRIPTION, FIND_STEP_DESCRIPTION]
 
const Home = () => {
    const [isSmallScreen, setSmallScreen] = useState(window.innerWidth <= 750);

    const updateMedia = () => {
        setSmallScreen(window.innerWidth <= 750);
    };
    

    useEffect(() => {
        document.title = APP_TITLE;
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    let stepComponents = steps.map((step, index) => {
        return <Box key={index} width="300px" marginLeft="auto" marginRight="auto">
            <Card elevation={0} style={{height: "250px", borderRadius: "10%"}}>
                <CardMedia
                    component="img"
                    sx={{ objectFit: "contain", p: 3, boxSizing: "border-box", height: "100%" }}
                    image={`${process.env.PUBLIC_URL}/${stepImages[index]}`}
                />
            </Card>
            <Box ml={1} mr={1} mt={4} >
                <Typography variant="h5" component="div" sx={{textAlign: "center", fontWeight: 'bold'}} >{`${index + 1}. ${step}`}</Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ textAlign: "center" }}>{stepDescriptions[index]}</Typography>
            </Box>
        </Box>
    });

    let perkLefts = [
        <Box key="inputPerkText" m={{sx: 4, sm: 5, md: 6}}>
            <Typography variant="h4" component="div" sx={{textAlign: "center", fontWeight: 'bold', mb: 3}} >
                {INPUT_PERK_TITLE}
            </Typography>
            <Typography variant="body1" component="div" sx={{textAlign: "center"}} >
                {INPUT_PERK_DESCRIPTION}
            </Typography>
        </Box>, 
        <Paper key="linkPerkImage" elevation={3} component="img"  src="/link.png" sx={{width: "100%", borderRadius: 3}}/>,
        <Box key="solverPerkText" m={{sx: 4, sm: 5, md: 6}}>
            <Typography variant="h4" component="div" sx={{textAlign: "center", fontWeight: 'bold', mb: 3}} >
                {SOLVER_PERK_TITLE}
            </Typography>
            <Typography variant="body1" component="div" sx={{textAlign: "center"}} >
                {SOLVER_PERK_DESCRIPTION}
            </Typography>
        </Box>
    ];

    let perkRights = [
        <Paper key="inputPerkImage" elevation={3} component="img"  src="/perk1.png" sx={{width: "100%"}}/>,
        <Box key="linkPerkText" m={{sx: 4, sm: 5, md: 6}}>
            <Typography variant="h4" component="div" sx={{textAlign: "center", fontWeight: 'bold', mb: 3}} >
                {LINK_PERK_TITLE}
            </Typography>
            <Typography variant="body1" component="div" sx={{textAlign: "center"}} >
                {LINK_PERK_DESCRIPTION}
            </Typography>
        </Box>,
        <Paper key="solverPerkImage" elevation={0} component="img"  src="/graph.png" sx={{width: "100%", maxWidth: "450px"}}/>
    ];

    return (
        <React.Fragment>
            <Container maxWidth="xl" >
                <Box display="flex" flexWrap="wrap" sx={{
                        backgroundImage: `radial-gradient(rgba(255,255,255,0.9), rgba(255,255,255,1)), url("/background.png")`,
                        width: "100%",
                        position: 'relative'
                    }}>
                    <ThemeProvider theme={theme}>
                        <Box m={{xs:3, sm:8, md: 15}} mt={{xs: 6, sm: 16, md: 25}} sx={{backgroundColor: 'transparent'}}>
                            <Typography variant="h1" mb={5} sx={{ fontWeight: 'bold'}}>{MAIN_TITLE}</Typography>
                            <Typography variant="h3" color="gray" mb={{xs:5, sm:10, md: 15}} sx={{ fontWeight: 'regular' }} >{MAIN_SUBTITLE}</Typography>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Button href="/explore" onClick={() => log("button", {name: "Home - " + MAIN_BUTTON_TEXT})} variant="contained" sx={{fontWeight: 700}}>{MAIN_BUTTON_TEXT}</Button>
                                <Button href="/pricing" onClick={() => log("button", {name: "Home - " + SECONDARY_BUTTON_TEXT})} variant="outlined">{SECONDARY_BUTTON_TEXT}</Button>
                            </Stack>
                            <Typography variant="body1" color="gray" mt={{xs:2, sm:3, md: 4}} sx={{ fontWeight: 'regular' }} >{BUTTON_SUBTITLE}</Typography>
                        </Box>
                    </ThemeProvider>
                </Box>
                <Box display="flex" flexWrap="wrap" gap={2} m={{xs:2, sm:8, md: 15}} mt={{xs:3, sm:6, md: 9}} sx={{backgroundColor: 'white'}}>
                    {scheduleTypes.map((type, index) => {
                        const Icon = icons[index];
                        return <Card key={index} style={{ border: "none", boxShadow: "none", flexGrow: 1, flexBasis: "40ch" }} >
                            <CardContent>
                                <Stack direction="row" alignItems="flex-start" gap={1}>
                                    <Icon></Icon>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                        <Typography component="div" variant="h7" mb={2}>
                                            <b>{type} scheduler</b>
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {descriptions[index]}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    })}
                </Box>
                <Typography variant="h6" component="div" sx={{textAlign: "center", fontWeight: 'bold', mb: 5}} >
                        ... and more
                </Typography>
            </Container>
            <Container maxWidth='100%' style={{ background: getColor("lightBlue", 50)}} sx={{pt: 5, pb: 5}}>
                <Container maxWidth="xl" >
                    <Typography variant="h5" component="div" sx={{textAlign: "center", fontWeight: 'bold', mb: 5}} >
                        {STEP_TITLE}
                    </Typography>
                    { isSmallScreen ? 
                    <Carousel autoPlay={false}>
                        {stepComponents}
                    </Carousel> :
                    <Stack direction="row" alignItems="stretch" justifyContent="space-evenly" gap={1}>
                        {stepComponents}
                    </Stack> }
                </Container>
            </Container>
            {perkLefts.map((perkLeft, index) => {
                let innerContent = <Container maxWidth="xl" >
                    <Grid container spacing={8} p={1} pt={10} pb={10} columns={{xs: 6, md: 12 }}>
                        <Grid item xs={6} display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                            {perkLeft}
                        </Grid>
                        <Grid item xs={6} display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                            {perkRights[index]}
                        </Grid>
                    </Grid>
                </Container>
                if (index % 2 === 1) {
                    return <Container key={index} maxWidth='100%' style={{ background: getColor("lightBlue", 50)}} sx={{pt: 5, pb: 5}} >
                        {innerContent}
                    </Container>
                }
                return innerContent;
            })}
        </React.Fragment>
    );
};


export default Home;