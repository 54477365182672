import { addField, fromDateString, fromTimeString, getField, toDateString, toTimeString, toDayString } from "./DateTimeUtils";
import { getStartDateOffset } from "./TableUtils";

export function defaultValues(dict, defaultDict) {
    if (dict.constructor === Object && defaultDict.constructor === Object) {
        for (let key in defaultDict) {
            if (defaultDict[key].constructor === Object) {
                if (dict[key] === undefined) {
                    dict[key] = {};
                }
                defaultValues(dict[key], defaultDict[key]);
            } else {
                if (dict[key] === undefined) {
                    dict[key] = defaultDict[key];
                }
            }
        }
    }
}

export function dataCompressor(data, intervalFn) {
    let intervalsMap = {};
    if (data === undefined) {
        return intervalsMap;
    }
    for (let i = 0; i < data.length; i += 1) {
        for (let j = 0; j < data[i].length; j += 1) {
            if (intervalsMap[i] === undefined) {
                intervalsMap[i] = [];
            }
            intervalsMap[i].push(intervalFn(data[i][j]));
        }
    }
    return intervalsMap;
}

export function scaleData(scale) {
    return (interval) => {
        interval = [...interval];
        interval[0] *= scale;
        interval[1] *= scale;
        return interval;
    }
}

export function compressAndScaleData(data, scale) {
    let intervalsMap = {};
    if (data === undefined) {
        return intervalsMap;
    }
    for (let i = 0; i < data.length; i += 1) {
        for (let j = 0; j < data[i].length; j += 1) {
            if (intervalsMap[i] === undefined) {
                intervalsMap[i] = [];
            }
            intervalsMap[i].push([...data[i][j]]);
        }
    }

    for (let key in intervalsMap) {
        let intervals = intervalsMap[key];
        for (let j = 0; j < intervals.length; j += 1) {
            intervals[j][0] *= scale;
            intervals[j][1] *= scale;
        }
    }

    return intervalsMap;
}

export function process1dData(data, startDay, endDay, selectAll, timeFormat, metadata, denormFn, intervalFn) {
    if (selectAll) {
        return dataCompressor([...Array(metadata["numRows"])].map(() => [[0, metadata["span"]]]), intervalFn);
    } else {
        return dataCompressor(denormFn(data, startDay, endDay, timeFormat), intervalFn);
    }
}

export function process2dData(datas, startDays, endDays, selectAlls, timeFormat, metadata, denormFn, intervalFn) {
    let processedData = [];
    for (let i = 0; i < datas.length; i += 1) {
        processedData.push(process1dData(datas[i], startDays[i], endDays[i], selectAlls[i], timeFormat, metadata, denormFn, intervalFn));
    }
    return processedData;
}

export function process3dData(datas, startDays, endDays, selectAlls, timeFormat, metadata, denormFn, intervalFn) {
    let processedData = [];
    for (let i = 0; i < datas.length; i += 1) {
        processedData.push(process2dData(datas[i], startDays[i], endDays[i], selectAlls[i], timeFormat, metadata, denormFn, intervalFn));
    }
    return processedData;
}

export function print(title, data, scheduleStart, scheduleEnd, scheduleSelectAll, scheduleTimeFormat, startDate, timeFormat) {
    let details =  scheduleSelectAll ? "Always available" : data === undefined || timeFormat !== scheduleTimeFormat ? "No data" : undefined;
    if (timeFormat) {
      details = details || 
      data.map((row, index) => {
        if (row.length > 0) {
          let date = fromDateString(startDate);
          addField(index, "day", date);
          return toDateString(date, "L") + ": " + row.map((interval) => {
            const startTime = fromTimeString(scheduleStart);
            addField(interval[0], "minute", startTime);
            const endTime = fromTimeString(scheduleStart);
            addField(interval[1], "minute", endTime);
            return `${toTimeString(startTime, 'M')} - ${toTimeString(endTime, 'M')}`;
          }).join(', ');
        }
      }).filter(entry => entry !== undefined).join('\n');
    } else {
      startDate = fromDateString(startDate);
      addField(getStartDateOffset(scheduleStart, scheduleEnd, getField("day", startDate)), "day", startDate);
      details = details || 
      data.map((row, index) => {
        if (row.length > 0) {
          let date = new Date(startDate.getTime());
          addField(index * 7, "day", date);
          return `Week of ${toDateString(date, "M")}: ${row.map((interval) => {
            const startDay = new Date(date.getTime());
            addField(interval[0], "day", startDay);
            const endDay = new Date(date.getTime());
            addField(interval[1] - 1, "day", endDay);
            if (startDay.getTime() === endDay.getTime()) {
                return toDayString(startDay, 'M');
            }
            return `${toDayString(startDay, 'M')} - ${toDayString(endDay, 'M')}`;
          }).join(', ')}`;
        }
      }).filter(entry => entry != undefined).join('\n');
    }
    if (details === undefined || details === "") {
      details = "No data";
    }
    return `${title}\n${details}`;
}

export function printSolution(solution) {
    if (solution === undefined) {
        return "No schedule found."
    }

    let {data, timeFormat, metadata} = solution;
    return data.map((datapoint) => {
        let transposedData = {};
        for (let [key, value] of Object.entries(datapoint.table.solutionData)) {
            for (let row of value) {
                let title = row.label.join(",");
                if (transposedData[title] === undefined) {
                    transposedData[title] = [];
                }
                if (transposedData[title][key] === undefined) {
                    transposedData[title][key] = [];
                }
                transposedData[title][key].push(row.interval);
            }
        }

        //Clean up
        for (let key in transposedData) {
            transposedData[key].map((value) => value === undefined ? [] : value.sort((a, b) => a[0] - b[0] || a[1] - b[1]));
        }

        let details = [];
        for (let key in transposedData) {
            details.push(print(key, transposedData[key], metadata["scheduleStart"], metadata["scheduleEnd"], false, timeFormat, metadata["startDate"], timeFormat));
        }
        return details.join('\n\n');
    }).join('\n\n');
} 