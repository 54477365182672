import React from 'react';
import ScheduleCard from '../../components/ScheduleCard';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/SSolver';
import { getColor } from '../../utils/ColorMapper';
import { generateNcSlotContent } from '../../utils/SlotContentFactory';
import StaticSlotListList from '../../components/StaticSlotListList';
import { process3dData, scaleData } from '../../utils/DataUtils';
import { INVALID_SLOT_DELETE_MESSAGE, MAX_SLOT_ADD_MESSAGE, MAX_SLOT_WARNING, MAX_SUB_SLOT_WARNING, SELECTION_SCHEDULER_NAME } from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {MultiDisplayTable} from "../../components/MultiDisplayTable";

class SelectionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/selection_icon.png"/>,
            slotList: () => <StaticSlotListList contentFactory={generateNcSlotContent(this.state.time)} />,
            displayTable: () => <MultiDisplayTable/>
        };
    }
    dialogAction (action) {
        switch(action) {
            case "add":
                return (properties) => {
                    if (properties.childSlot) {
                        this.slotsRef.current.addChildSlot(properties.attributes, this.slotsRef.current.getChildDefaultAttributesContent());
                    } else {
                        this.slotsRef.current.addSlot(properties.attributes, this.slotsRef.current.getDefaultAttributesContent());
                    }
                }
            default:
                return super.dialogAction(action);
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "add":
                    if (this.state.scheduleActive && this.slotsRef.current.getSize() >= 6 ||
                        !this.state.scheduleActive && this.slotsRef.current.getSize() > 0 && this.slotsRef.current.getChildSize() >= 6) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max 6)`});
                    } else {
                        if (!this.state.scheduleActive) {
                            let [content, attributes] = this.slotsRef.current.getChildDefaultAttributesContent();
                            this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, {...passthroughVars, childSlot: true});
                        } else {
                            let [content, attributes] = this.slotsRef.current.getDefaultAttributesContent();
                            this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, {...passthroughVars, childSlot: false});
                        }
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (intervalsMaps.length > 6) {
            intervalsMaps = intervalsMaps.slice(0, 6);
            messages[0] = {severity: "warning", content: MAX_SLOT_WARNING};
            for (let i = 0; i < intervalsMaps.length; i += 1) {
                if (intervalsMaps[i].length > 6) {
                    intervalsMaps[i] = intervalsMaps[i].slice(0, 6);
                    messages[0] = {severity: "warning", content: MAX_SUB_SLOT_WARNING};
                }
            }
        }
        let slotsInfo = this.slotsRef.current.getSlotsInfo();
        let {result, missing} = findSolution(timesMap, intervalsMaps, slotsInfo["lengthArray"], metadata["span"] * metadata["scale"], metadata["wrap"]);
        let solutionData = {};
        if (result === undefined || Object.keys(result).length === 0) {
            return [{messages: [{severity: "error", content: "Could not find an arrangment for any slot"}]}];
        }
        for (let [index, selectedIndex] of Object.entries(result)) {
            let selectedMap = intervalsMaps[index][selectedIndex];
            for (let rowIndex in selectedMap) {
                if (solutionData[rowIndex] === undefined) { 
                    solutionData[rowIndex] = [];
                }
                
                let array = solutionData[rowIndex];
                for (let j = 0; j < selectedMap[rowIndex].length; j += 1) {
                    array.push({interval: selectedMap[rowIndex][j], borderColor: getColor(slotsInfo["colorArray"][index], 800), color: getColor(slotsInfo["colorArray"][index], 500), label: [slotsInfo["nameArray"][index]]})
                }
            }
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => slotsInfo["nameArray"][index]).join(", ")});
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    processSlotListData(slotListDatas, slotListStartDays, slotListEndDays, slotListSelectAlls, metadata) {
        return process3dData(slotListDatas, slotListStartDays, slotListEndDays, slotListSelectAlls, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        document.title = `${capitalize(SELECTION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(SelectionSchedulerPage);