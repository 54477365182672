import React from 'react';
import DynamicScheduleCard from '../../components/DynamicScheduleCard';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import { generateNclSlotContent } from '../../utils/SlotContentFactory';
import { capitalize } from '../../utils/StringUtils';
import {INVALID_SLOT_DELETE_MESSAGE, MAX_SLOT_ADD_MESSAGE, SLOTS_PER_PLAN, UNION_SCHEDULER_NAME} from '../../utils/Constants';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {getColor} from "../../utils/ColorMapper";
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";

class DynamicUnionSchedulerPage extends DynamicSchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCard key="scheduleCard" icon="/union_icon.png" {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNclSlotContent(this.state.time)} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" {...this.displayProps()} />
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            let limit = SLOTS_PER_PLAN[this.props.id][this.state.planName];
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= limit) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    async findSolution(timesMap, intervalsMaps) {
        let slotsInfo = this.slotsRef.current.getSlotsInfo();
        let slots = intervalsMaps.map((intervalsMap, index) => { return {"rows": intervalsMap, "length": slotsInfo["lengthArray"][index]}; });
        let input = {"schedule":{"rows": timesMap}, "slots": slots};
        //console.log(timesMap, intervalsMaps);
        let response = await this.callSolver("separate_time", input);
        //console.log("response", response);
        if (response === undefined) {
            return [{messages: [{severity: "error", content: "An error occurred. Please try again"}]}];
        }
        let messages = [];
        if (response.status.result === "FAILURE") {
            messages.push({severity: "error", content: "Could not find an arrangement for any slot"});
        } else if (response.status.result === "PARTIAL_SUCCESS") {
            messages.push({severity: "warning", content: `Could not find an arrangement for 
                ${response.status.messageData?.failureIndices?.map((index) => slotsInfo["nameArray"][index]).join(", ")}`});
        }
        let solutionData = {};
        for (let rowIndex of Object.keys(response.schedule.table)) {
            solutionData[rowIndex] = response.schedule.table[rowIndex].map((entry) => {
                let index = entry.data.index;
                return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 800), "color": getColor(slotsInfo["colorArray"][index], 500), "label": [slotsInfo["nameArray"][index]]};
            });
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    componentDidMount() {
        document.title = `${capitalize(UNION_SCHEDULER_NAME)} schedule | Snug Scheduler`;
    }
}

export default withErrorBoundary(DynamicUnionSchedulerPage)