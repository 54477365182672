export const NOT_FOUND_TEXT = "Sorry, the page you have requested does not exist." ;
export const NOT_FOUND_SUBTEXT = "Make sure that you have the correct URL or the schedule exists.";
export const SUBMIT_TEXT = "Thank you for your submission.";
export const SUBMIT_SUBTEXT = "We will be reviewing your feedback soon.";
export const ACCESS_DENIED_TEXT = "Sorry, your access to this schedule was denied.";
export const ACCESS_DENIED_SUBTEXT = "Contact the schedule owner to provide edit access.";
export const ERROR_TEXT = "An error has occurred.";


//HomePage
export const APPOINTMENT_HOOK = "Ensure everyone can find a time within their availability to make an appointment.";
export const CLASS_HOOK = "Select the best class times that allows you to take the maximum amount of classes without overlapping.";
export const SHIFT_HOOK = "Create shift schedules that are well-organized and take into account both employee availability and your own scheduling needs.";
export const TASK_HOOK = "Efficiency arrange tasks to increase productivity and minimize downtime.";
export const MEETING_HOOK = "Find a suitable time that works well for everyone when scheduling large-scale meetings.";
export const ONE_ON_ONE_HOOK = "Schedule many one-on-one meetings in a row that can work within everyone's availability.";

export const APP_TITLE = "Snug Scheduler - Online Scheduling Tool";
export const MAIN_TITLE = "A Smarter Scheduling Tool";
export const MAIN_SUBTITLE = "Arrange the perfect schedule with the power of an automatic scheduler.";
export const MAIN_BUTTON_TEXT = "Use It Now";
export const SECONDARY_BUTTON_TEXT = "View Plans →";
export const BUTTON_SUBTITLE = "No login required. Certain features may not be available without an account.";
export const STEP_TITLE = "Use Snug Scheduler in 3 easy steps";
export const SELECT_STEP = "Select the right scheduler";
export const FILL_STEP = "Fill in availability info";
export const FIND_STEP = "Find the best schedule";
export const SELECT_STEP_DESCRIPTION = "Each scheduler can help you solve different types of problems like appointment or shift scheduling.";
export const FILL_STEP_DESCRIPTION = "Input availability for everyone on your own or share a link to have people fill in their own.";
export const FIND_STEP_DESCRIPTION = "Run the solver that analyzes the data and calculates the schedule that packs in the most events based on availability.";

export const INPUT_PERK_TITLE = "Intuitive input selection";
export const INPUT_PERK_DESCRIPTION = "Quickly fill in availability using our customized input selection solution. Easily adjust interval lengths, drag and move intervals around, and delete intervals with a click. Choose from a variety of input methods to fill in your availability, with experiences designed for both web and mobile.";
export const SOLVER_PERK_TITLE="How does it work?";
export const SOLVER_PERK_DESCRIPTION="Behind each schedule type is a custom solver that uses tailored data processing and computation to quickly provide you with a schedule that fits as many events as possible, as compactly as possible. Traditional scheduling tools operate at first-come-first-served basis, which may not be able to accommodate everyone. Snug Scheduler is the one-stop shop that helps you arrange high quality schedules for all of your scheduling needs.";
export const LINK_PERK_TITLE = "Shareable links";
export const LINK_PERK_DESCRIPTION="There is no need to do all the work yourself! You can share a link to have everybody fill in their own availability. With password protection, participants will only be able to view and edit their own schedules. You can also adjust editing permissions and view the results in real-time.";

//LoginPage
export const LOGIN_TITLE = "Login";

//ExplorePage
export const EXPLORE_TITLE = "Explore";
export const EXPLORE_SUBTITLE = "No login required. Start using these free online scheduling tools now. Certain features may not be available.";

export const UNION_SCHEDULER_NAME = "separate time";
export const INTERSECTION_SCHEDULER_NAME = "common time";
export const SELECTION_SCHEDULER_NAME = "selection";
export const DISTRIBUTION_SCHEDULER_NAME = "distribution";
export const FULFILLMENT_SCHEDULER_NAME = "fulfillment";

export const UNION_HOOK = "Find a schedule for separate events with different availability times.";
export const INTERSECTION_HOOK = "Find a common time where everyone is available.";
export const SELECTION_HOOK = "Find a schedule for separate events with times from lists of fixed options.";
export const DISTRIBUTION_HOOK = "Find an assignment of fixed tasks or shifts to those available to take them.";
export const FULFILLMENT_HOOK = "Find a flexible assignment of time or day shifts from preferred total time/days.";

export const UNION_DESCRIPTION = "You have a series of appointments or one-on-one meetings that you need to schedule at different times or one after another. Meetings can have different durations, and each are scheduled in one continuous time period.";
export const INTERSECTION_DESCRIPTION = "You have to find a common time to schedule a meeting with many people who have very different availability times.";
export const SELECTION_DESCRIPTION = "You have a set of classes or events with many fixed time options, but you have to pick one option for each so that no events or classes overlap.";
export const DISTRIBUTION_DESCRIPTION = "You have to distribute a set of fixed tasks or shifts to the person who is the most suitable match for it, according their availability.";
export const FULFILLMENT_DESCRIPTION = "You need to assign a certain number of slots or shifts for each time period throughout the day. Each person can have different availability times, and the preferred amount of time for which they want to be scheduled. The scheduled times do not necessarily need to be in one continuous interval, and can be split up with breaks in between.";

export const UNION_APPLICATIONS_ARRAY = ["Appointments", "Client booking", "Flexible-time job scheduling", "1-on-1s", "Interview scheduling"];
export const INTERSECTION_APPICATIONS_ARRAY = ["Meeting scheduling", "Group events"];
export const SELECTION_APPLICATIONS_ARRAY = ["College class selection", "Conference event scheduling"];
export const DISTRIBUTION_APPLICATIONS_ARRAY = ["Task allocation", "Fixed-shift delegation", "Route scheduling"];
export const FULFILLMENT_APPLICATIONS_ARRAY = ["Shift assignment", "Employee management", "Job scheduling"];


//PricingPage
export const PRICING_TITLE = "Pricing";
export const SELECT_PLAN = "Select Plan";
export const FREE_PLAN_NAME = "free";
export const SMALL_PLAN_NAME  = "small";
export const MEDIUM_PLAN_NAME  = "medium";
export const LARGE_PLAN_NAME  = "large";
export const EXTRA_LARGE_PLAN_NAME  = "extra-large";

export const PLAN_SLOT_MAP = {[FREE_PLAN_NAME]: 6, [SMALL_PLAN_NAME]: 12, [MEDIUM_PLAN_NAME]: 24, [LARGE_PLAN_NAME]: 36, [EXTRA_LARGE_PLAN_NAME]: 48};

export const MONTHLY = "Monthly";
export const ANNUALLY = "Annually";


export const SCHEDULE_ACCESS_FEATURE = "Access to all schedule types";
export const SLOT_FEATURE_TEMPLATE = "Up to {0} slots per schedule";
export const SAVE_FEATURE =  "Save and load schedules";
export const SHARE_FEATURE = "Share public access for slot input";
export const INTEGRATION_FEATURE = "Calendar app integration";
export const MULTI_SOLUTION_FEATURE = "View and compare multiple solutions";

export const SLOT_ACCESS_TABLE_TITLE = "Slot access by plan";
export const SLOT_ACCESS_TABLE_DESCRIPTION = "The following table shows the number of slots available for each plan and schedule type.";
export const SLOT_ACCESS_TABLE_SUB_DESCRIPTION = "The amount of slots for paid plans may increase in the future.";


//SupportPage
export const SUPPORT_TITLE = "Support";
export const TUTORIALS_NAME = "Tutorials";
export const TUTORIALS_LINK = "https://www.youtube.com/@SnugScheduler";
export const SUBMIT_ISSUE_NAME = "Submit an Issue";
export const SUBMIT_ISSUE_LINK = "/support/feedback";
export const CHAT_NAME = "SnugAI Chat";
export const CHAT_LINK = "/support/chat";

export const FAQ_1 = "Do I need to make an account or pay to use this scheduling app?";
export const FAQ_2 = "What features do I get for a subscription?";
export const FAQ_3 = "How many days can I schedule at once?";
export const FAQ_4 = "Is there a mobile app available to schedule on my phone?";
export const FAQ_5 = "How can I refund my subscription purchase?";
export const FAQ_6 = "What does this scheduling application do that is different from existing ones?";
export const ANSWER_1 = "No, you do not need to make an account to use the scheduling app. However, you will need to make an account and pay for a subscription to access premium features.";
export const ANSWER_2 = "With a subscription, you will be able to save and load schedules, share schedule inputs, and have access to more slots per schedule. Higher tier subscriptions will have access to even more slots per schedule.";
export const ANSWER_3 = "There is no limit for how many days for which you can configure your schedule; however, larger schedules may be harder to navigate and use.";
export const ANSWER_4 = "There is no mobile app for Snug Scheduler at the moment. However, you can still use the website on your phone through a mobile web browser. There are special features that improve the experience for mobile users.";
export const ANSWER_5 = "Refunds for subscriptions are not available. However, you can cancel your subscription at any time and still have access to the features until the end of the billing period. Please use the free version of the scheduler to test out the features before purchasing a subscription.";
export const ANSWER_6 = "Snug Scheduler serves a different purpose than existing scheduling tools. Rather than to organize events, it is designed to solve complex scheduling problems from availability data and give you the start and end times for your events. You can then use this information to organize your events in your calendar app of choice.";

//ChatPage
export const CHAT_INPUT_PLACEHOLDER = "Enter your question here";
export const CHAT_DISCLAIMER = "Chat information may be inaccurate. Double-check responses with a reliable source.";
export const BOT_INTRO_MESSAGE = "Hello, I am SnugAI. How may I assist you today with Snug Scheduler?";

//SettingsPage
export const YOUR_PLAN_TITLE = "Your plan";
export const PLAN_MANAGEMENT_TITLE = "Manage Subscription";
export const FEATURES_TITLE = "Features";
export const FEATURES_SUBTITLE_TEMPLATE = "The features that are available with your {plan} Plan subscription are:";
export const FULL_ACCESS_FEATURE = "Full access to all scheduling features";

export const FREE_ACCESS_LIMITATIONS = "You are unable to to create any schedules through the \"My Schedules\", but you still have access to the \"Explore\" page tools. The schedules you create will not be saved and paid features will not be available.";
export const PAID_ACCESS_ENCOURAGEMENT = "To create schedules with more slots and gain access to features such as saving, sharing, downloading, or exporting schedules, consider upgrading to a paid subscription.";

export const FULL_ACCESS_FEATURE_DETAIL = "Within each scheduling tool, you will also be able to print and export schedules, download results to a csv file, and transfer schedules to your calendar app.";
export const SCHEDULE_ACCESS_FEATURE_DETAIL = "You have access to all 5 scheduling tools, which cover applications ranging from meeting scheduling to task delegation. You will also gain instant full access to new scheduling tools as they release.";
export const SAVE_FEATURE_DETAIL =  "You will be able to create, save, and load your schedules and any changes you make are periodically autosaved. When your paid subscription ends, you will still be able to read from your saved schedules, but you will no longer be able to make any changes.";
export const SHARE_FEATURE_DETAIL = "You can enable or disable access to a shareable link for anyone to fill in their availability. Submissions made from the link will be immediately updated to your schedules.";
export const INTEGRATION_FEATURE_DETAIL = "Integrate your final arranged schedule into the calendar app of your choice. Support currently exists for Apple, Google, and Microsoft Calendar.";
export const SLOT_FEATURE_DETAIL_TEMPLATE = "For both schedule and slot inputs, you can add new entries and run the solver for schedules with up to {0} slots. You can upgrade or downgrade your plan at any time to accommodate your needs.";
export const MULTI_SOLUTION_FEATURE_DETAIL = "With a tabbed solution window, you can quickly switch between multiple schedule solutions, allowing you to easily choose the schedule with your preferred time allocations.";

export const CONNECTIONS_TITLE = "Calendar Connections"
export const CONNECTIONS_DESCRIPTION = "Connect your calendar app to Snug Scheduler to see your completed schedules in your calendar.";

export const CONNECTED_LABEL = "Connected";
export const NOT_CONNECTED_LABEL = "Not Connected";

export const DELETE_DATA_TITLE = "User data deletion";
export const DELETE_DATA_DESCRIPTION = "Permanently delete your account and all your data from Snug Scheduler.";
export const DELETE_DATA_BUTTON_TEXT = "Permanently Delete My Account";

//Footer
export const TWEET_LINK = "https://twitter.com/SnugScheduler";
export const FACE_LINK = "https://www.facebook.com/SnugScheduler";
export const INSTA_LINK = "https://www.instagram.com/snugscheduler/";
export const LINKED_LINK = "https://www.linkedin.com/company/snug-scheduler/";

export const TWEET_NAME = "twitter";
export const FACE_NAME = "facebook";
export const INSTA_NAME = "instagram";
export const LINKED_NAME = "linkedIn";
export const YOUTUBE_NAME = "youtube";

export const TERMS_OF_USE_LINK = "/terms_of_use";
export const PRIVACY_POLICY_LINK = "/privacy_policy";

export const TERMS_OF_USE_TITLE = "Terms";
export const PRIVACY_POLICY_TITLE = "Privacy";

export const PRODUCTS_TITLE = "Products";
export const PLANS_TITLE = "Plans";

//Errors
export const ERROR_TRY_AGAIN_MESSAGE = "An error occurred. Please try again later.";
export const CHAT_UNAVAILABLE_MESSAGE = "Chat is currently unavailable. Please try again later.";
export const OPERATION_FAILED = "Operation failed.";
export const LOGIN_FAILED_MESSAGE = "Login attempt was unsuccessful. Please try again.";
export const WRITE_FAILED_DETAIL = "Check your internet connection and make sure you have an active subscription.";

//SchedulerPage
export const INVALID_SLOT_DELETE_MESSAGE = "Please select a valid slot to delete.";
export const MIN_SLOT_DELETE_MESSAGE = "Unable to delete any more slots (min 1).";
export const MAX_SLOT_ADD_MESSAGE = "Unable to add any more slots."
export const MAX_SLOT_WARNING = "The schedule only considers the first 6 slots.";
export const MAX_SUB_SLOT_WARNING = "The schedule only considers the first 6 slots of each.";
export const FEATURE_SUBSCRIPTION_ALERT  = "This feature is only available with a subscription.";
export const CHANGES_SAVED_ALERT = "Your changes have been saved.";
export const SCHEDULER_RUNNING_ALERT = "The scheduler is currently running...";

//Dialogs
export const DELETE_ACCOUNT_DIALOG_TITLE = "Delete this account?";
export const DELETE_ACCOUNT_DIALOG_CONTENT = "Doing so will permanently delete your account and all of your data. Any active subscription will be forfeited. This action cannot be undone.";
export const DELETE_ACCOUNT_DIALOG_CONFIRMATION = "Confirm you want to delete your account by typing in your name.";
export const DELETE_ACCOUNT_DIALOG_POSITIVE = "Delete";

export const SHARE_SLOT_TITLE = "Allow shared slot input";
export const SHARE_SLOT_DESCRIPTION = "Send a sharable link that allows anyone to add and edit their own slots. The link does not allow anyone else to access this schedule or the change any other slot. The changes you make to the slot afterwards will not be reflected in the shared link.";

//Slot Numbers
export const SLOTS_PER_PLAN= {[UNION_SCHEDULER_NAME]: {[FREE_PLAN_NAME]:6, [SMALL_PLAN_NAME]: 12, [MEDIUM_PLAN_NAME]: 24, [LARGE_PLAN_NAME]: 36, [EXTRA_LARGE_PLAN_NAME]: 48 },
    [INTERSECTION_SCHEDULER_NAME]: {[FREE_PLAN_NAME]:6, [SMALL_PLAN_NAME]: 12, [MEDIUM_PLAN_NAME]: 24, [LARGE_PLAN_NAME]: 36, [EXTRA_LARGE_PLAN_NAME]: 48 },
    [SELECTION_SCHEDULER_NAME]: {[FREE_PLAN_NAME]:6, [SMALL_PLAN_NAME]: 12, [MEDIUM_PLAN_NAME]: 24, [LARGE_PLAN_NAME]: 36, [EXTRA_LARGE_PLAN_NAME]: 48 },
    [DISTRIBUTION_SCHEDULER_NAME]: {[FREE_PLAN_NAME]:6, [SMALL_PLAN_NAME]: 12, [MEDIUM_PLAN_NAME]: 24, [LARGE_PLAN_NAME]: 36, [EXTRA_LARGE_PLAN_NAME]: 48 },
    [FULFILLMENT_SCHEDULER_NAME]: {[FREE_PLAN_NAME]:6, [SMALL_PLAN_NAME]: 12, [MEDIUM_PLAN_NAME]: 24, [LARGE_PLAN_NAME]: 36, [EXTRA_LARGE_PLAN_NAME]: 48 }};