export function fromTimeString(timeString) {
    return new Date(Date.UTC(0, 0, 0, timeString.slice(0, 2), timeString.slice(3, 5)));
}

export function fromDateString(dateString) {
    return new Date(dateString);
}

export function getField(field, date) {
    switch(field) {
        case "year":
            return date.getUTCFullYear();
        case "month":
            return date.getUTCMonth();
        case "date":
            return date.getUTCDate();
        case "day":
            return date.getUTCDay();
        case "hour":
            return date.getUTCHours();
        case "minute":
            return date.getUTCMinutes();
        default:
            return date.getUTCSeconds();
    }
}

export function addField(amount, field, date) {
    switch(field) {
        case "year":
            date.setYear(date.getFullYear() + amount);
            break;
        case "month":
            date.setMonth(date.getMonth() + amount);
            break;
        case "week":
            date.setDate(date.getDate() + 7 * amount);
            break;
        case "day":
            date.setDate(date.getDate() + amount);
            break;
        case "hour":
            date.setHours(date.getHours() + amount);
            break;
        case "minute":
            date.setMinutes(date.getMinutes() + amount);
            break;
        default:
            date.setSeconds(date.getSeconds() + amount);
            break;
    }
}

function pad2(num) {
    if (num < 10) {
        return "0" + num;
    }
    return num.toString();
}

export function toTimeString(time, variant, prevTime) {
    let hour = getField("hour", time);
    let minute = getField("minute", time);
    switch(variant) {
        case "S": {
            let timeString;
            if (getField("hour", prevTime) !== hour) {
                timeString = mod(hour - 1, 12) + 1;
                if (minute > 0) {
                    timeString += ":" + pad2(minute);
                }
            } else {
                timeString = pad2(minute);
            }
            return timeString;
        }
        case "M":
            return hour + ":" + pad2(minute);
        case "L":
            return (mod(hour - 1, 12) + 1) + ":" + pad2(minute) + (hour < 12 ? "AM" : "PM");
        default:
            return "";
    }
}

const day_map = {"Sun": "Sunday", "Mon": "Monday", "Tue": "Tuesday", "Wed": "Wednesday", "Thu": "Thursday", "Fri": "Friday", "Sat": "Saturday"};
export function toDayString(date, variant) {
    switch(variant) {
        case "S":
            return date.toUTCString().slice(0, 1);
        case "M":
            return date.toUTCString().slice(0, 3);
        case "L":
            return day_map[date.toUTCString().slice(0, 3)];
        default:
            return "";
    }
}

export function toDateString(date, variant) {
    switch(variant) {
        case "S":
            return getField("date", date)
        case "M":
            return (getField("month", date) + 1) + "/" + getField("date", date);
        case "L":
            return date.toUTCString().slice(0, 3) + ", " + date.toUTCString().slice(8, 11) + " " + getField("date", date);
        default:
            return "";
    }
}

export function mod(num, modulo) {
    return ((num % modulo) + modulo) % modulo;
}

export function findDayGap(dayStart, dayEnd) {
    return mod(dayEnd - dayStart, 7);
}

export function getDaysDifference(date1, date2) {
    // Convert the dates to Date objects if they are not already
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(d2 - d1);

    // Convert milliseconds to days
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays;
}