import React from "react";
import SlotListList from "./SlotListList";
import DynamicSlot from "./DynamicSlot";
import DynamicSlotList from "./DynamicSlotList";
import { generateNSlotContent } from "../utils/SlotContentFactory";
import { doc, collection, onSnapshot, query, orderBy, addDoc, runTransaction } from "firebase/firestore";
import { db } from "../firebase"
import { defaultValues } from "../utils/DataUtils"
import {nextChar, FIRST_CHAR, findAppendedOrder} from '../utils/OrderEncoderUtils'
import {OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";


export default class DynamicStaticSlotListList extends SlotListList {
    constructor(props) {
        super(props);
        this.Slot = <DynamicSlot rootPath={this.props.rootPath}/>;
        this.order = {};
        onSnapshot(query(this.props.rootPath, orderBy("order")), (querySnapshot) => {
            let orderToSlotIndex = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                defaultValues(data, {attributes: {color: "blue", length: 5}});
                this.dataAttributes[doc.id] = data.attributes;
                this.slotRefs[doc.id] = this.slotRefs[doc.id] || React.createRef();
                orderToSlotIndex.push(doc.id);
                this.order[doc.id] = data.order;
                //console.log(`${doc.id} => ${JSON.stringify(data)}`);
            })
            this.setState({orderToSlotIndex: orderToSlotIndex, activeSlotId: this.state.activeSlotId || orderToSlotIndex[orderToSlotIndex.length - 1]});
            if (orderToSlotIndex.length === 0) { //can't rely on child to unblock
                this.props.onLoadFinish();
            }
        }, () => {
            this.props.propChangeAction({"notFound": true});
        });
    }

    getAuxContentList(slotNumber) {
        if (this.slotListRefs[slotNumber] === undefined) {
            this.slotListRefs[slotNumber] = React.createRef();
        }
        let contentList = super.getAuxContentList(slotNumber);
        contentList.push(<DynamicSlotList key={slotNumber} ref={this.slotListRefs[slotNumber]} data={this.props.data} slotClickAction={this.childSlotClickAction(slotNumber)}
            propChangeAction={this.props.propChangeAction} dataChangeAction={this.props.dataChangeAction} displayMessage={this.props.displayMessage}
            timeFormat={this.props.timeFormat} selected={this.props.selected && this.state.activeSlotId === slotNumber}
            contentFactory={generateNSlotContent(this.props.timeFormat)} rootPath={collection(this.props.rootPath, slotNumber, "slots")}
            onLoadFinish={this.props.onLoadFinish} rotateColors={false}/>);
        return contentList;
    }

    addSlot(attributes) {
        let orders = this.state.orderToSlotIndex.map((slotNumber) => this.order[slotNumber]);
        let newSlotKey = findAppendedOrder(orders);

        if (this.state.orderToSlotIndex.length > 0) {
            defaultValues(attributes, this.dataAttributes[this.state.orderToSlotIndex.slice(-1)]);
        } else {
            defaultValues(attributes, {name: "Untitled Slot", color: "blue", length: this.props.timeFormat ? 30 : 1});
        }

        addDoc(this.props.rootPath, {
            attributes: attributes,
            order: newSlotKey
        }).then((docRef) => {
            addDoc(collection(this.props.rootPath, docRef.id, "slots"), {
                attributes: {name: attributes.name + " (Slot 1)", color: attributes.color},
                order: nextChar(FIRST_CHAR)
            }).then(() => {
                this.switchSlot(docRef.id);
            }).catch(() => {
                this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
            });
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
    }

    deleteActiveSlot() {
        let activeSlotId = this.state.activeSlotId;
        if (!this.props.selected || activeSlotId === undefined) {
            return undefined;
        }
        let orderLength = this.slotListRefs[activeSlotId].current.state.orderToSlotIndex.length;
        let firstSubElementId = this.slotListRefs[activeSlotId].current.state.orderToSlotIndex[0];
        let newSlotId = super.deleteActiveSlot();

        if (orderLength <= 1) {
            runTransaction(db, async (transaction) => {
                transaction.delete(doc(this.props.rootPath, activeSlotId, "slots", firstSubElementId));
                transaction.delete(doc(this.props.rootPath, activeSlotId));
            });
        } else {
            this.slotListRefs[activeSlotId].current.deleteActiveSlot();
        }

        return newSlotId;
    }

    saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll) {
        if (this.slotRefs[this.state.activeSlotId] !== undefined) { //may occur if last slot was just deleted
            this.slotRefs[this.state.activeSlotId].current.saveData(undefined); //save field attributes but not data
            super.saveDataToActive(data, timeFormat, scheduleStart, scheduleEnd, selectAll);
        }
    }
}