import React from "react";
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ClickTable } from '../components/ClickTable'
import { TouchTable } from '../components/TouchTable'
import Paper from '@mui/material/Paper';
import getTableRowsAndCols from "../utils/TableUtils";
import { db } from "../firebase"
import { doc, onSnapshot, setDoc, getDoc} from "firebase/firestore"; 
import Snackbar from '@mui/material/Snackbar';
import sha1 from 'crypto-js/sha1';
import {convert_word_array_to_uint8Array, bs60} from '../utils/HashUtils'
import { useSearchParams, useParams }from 'react-router-dom';
import {defaultValues} from '../utils/DataUtils'
import TextPage from "./TextPage";
import LoadingPage from "./LoadingPage";
import { normalizeDayData, normalizeTimeData, denormalizeDayData, denormalizeTimeData } from "../utils/TableUtils";
import { ACCESS_DENIED_SUBTEXT, ACCESS_DENIED_TEXT } from "../utils/Constants";
import { defaultEndDate, defaultEndTime, defaultStartDate, defaultStartTime } from "../initial_data";


class InnerSharePage extends React.Component {
    constructor(props) {
        super(props);
        this.paperRef = React.createRef();
        this.tableRef = React.createRef();
        this.state = {needSignIn: true}
        if (!this.props.searchParams.get("key")) {
            this.state.access = false;
        } else {
            onSnapshot(doc(db, "schedules", this.props.params.id, this.props.searchParams.get("key"), "DEFAULT"), (doc) => {
                if (doc.exists()) {
                    let data = doc.data();
                    let defaultData = {endDate: defaultEndDate, endDay: 6, endTime: defaultEndTime, interval: 30, sharing: {enabled: false, key: "0"}, span: 1, startDate: defaultStartDate, startDay: 0, startTime: defaultStartTime, timeFormat: true}
                    defaultValues(data, defaultData);
                    this.setState({access: true, time: data.timeFormat, startTime: data.startTime, endTime: data.endTime, startDate: data.startDate, 
                        endDate: data.endDate, startDay: data.startDay, endDay: data.endDay, interval: data.interval, span: data.span}, this.loadSlotDataTable)
                } else {
                    this.setState({access: false})
                }
            }, () => {
                this.setState({access: false})
            });
        }
    }

    getDocKey(name, password) {
        let concatenated = name.length + name + password.length + password;
        let hashed = convert_word_array_to_uint8Array(sha1(concatenated))
        return bs60.encode(hashed);
    }

    handleConfirm = () => {
        if (!this.state.name) {
            this.setState({nameError: true});
        } 
        if (!this.state.password) {
            this.setState({passwordError: true});
        }
        if (this.state.name && this.state.password) {
            getDoc(doc(db, "schedules", this.props.params.id, this.props.searchParams.get("key"), "DEFAULT", "slots", this.getDocKey(this.state.name, this.state.password))).then((doc) => {
                let isNew;
                if (doc.exists()) {
                    let data = doc.data();
                    this.slotData = data;   
                    isNew = false;
                } else {
                    isNew = true;
                    this.slotData = {name: this.state.name, scheduleData: {timeFormat: this.state.time}}
                }
                this.loadSlotDataTable();
                this.setState({needSignIn: false, isNew: isNew})
            });
        }
    }

    loadSlotDataTable() {
        if (this.tableRef.current && this.slotData && this.slotData?.scheduleData?.data) {
            let {data, scheduleStart, scheduleEnd, timeFormat} = this.slotData.scheduleData;
            data = JSON.parse(data);
            if (data && this.state.time === timeFormat) {
                if (this.state.time) {
                    data = denormalizeTimeData(data, this.state.interval, scheduleStart, this.state.startTime, this.state.endTime)
                } else {
                    data = denormalizeDayData(data, this.state.span, scheduleStart, scheduleEnd, this.state.startDay, this.state.endDay)
                }
                this.tableRef.current.loadData(data, false, this.state.time === timeFormat) //TODO: Add selectAll
            }
        }
    }

    handleSave = () => {
        //console.log("docKey", this.getDocKey(this.state.name, this.state.password))
        //console.log("params", this.props.params, "searchParams", this.props.searchParams.get("key"))
        if (this.tableRef.current) {
            [this.slotData.scheduleData.data, this.slotData.scheduleData.selectAll] = this.tableRef.current.returnData();
            if (this.state.time) {
                this.slotData.scheduleData.data = normalizeTimeData(this.slotData.scheduleData.data, this.state.interval);
            } else {
                this.slotData.scheduleData.data = normalizeDayData(this.slotData.scheduleData.data, this.state.span, this.state.startDay, this.state.endDay);
            }
            this.slotData.scheduleData.data = JSON.stringify(this.slotData.scheduleData.data);
            this.slotData.scheduleData.timeFormat = this.state.time;
            this.slotData.scheduleData.scheduleStart = this.state.startTime;
            this.slotData.scheduleData.scheduleEnd = this.state.endTime;
            setDoc(doc(db, "schedules", this.props.params.id, this.props.searchParams.get("key"), "DEFAULT", "slots", this.getDocKey(this.state.name, this.state.password)), this.slotData);
        }
        this.setState({snackbarOpen: true});
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value}); 
        this.setState({nameError: false});
    }

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value}); 
        this.setState({passwordError: false});
    }


    render() {
        if (this.state.access === false) {
            return (<TextPage text={ACCESS_DENIED_TEXT} subtext={ACCESS_DENIED_SUBTEXT}/>);
        } else if (this.state.access === undefined) {
            return <LoadingPage />;
        }

        let [tableRows, tableCols, , terminalCols] = getTableRowsAndCols(this.state.time, this.state.startTime, this.state.endTime, this.state.startDate, this.state.endDate, this.state.interval, this.state.startDay, this.state.endDay, this.state.span);
        let table;
        if (window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/i)) {
            table = <TouchTable ref={this.tableRef} tableRows={tableRows} tableCols={tableCols} terminalCols={terminalCols} height={50} parentRef={this.paperRef} color={"blue"}/>
        } else {
            table = <ClickTable ref={this.tableRef} tableRows={tableRows} tableCols={tableCols} terminalCols={terminalCols} height={50} parentRef={this.paperRef} color={"blue"}/>
        }
        

        return (
            <Container maxWidth="xl">
                { this.state.isNew !== undefined && <h1>{this.state.isNew ? "Enter a new time availability " : "Edit your existing time availability entry" }</h1> }
                <Dialog open={this.state.needSignIn} onKeyDown={event => {
                    if (event.key === 'Enter') {
                        this.handleConfirm();
                        event.preventDefault();
                    }
                }}>
                    <DialogTitle>Sign In</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter your name and a password. You can use these credentials to edit your entries later.
                        </DialogContentText>
                        <Stack m={4} spacing={2}>
                            <TextField id="standard-name" error={this.state.nameError} label="Name" variant="standard" onChange={this.handleNameChange} />
                            <TextField id="standard-password" error={this.state.passwordError} label="Password" type="password" variant="standard" onChange={this.handlePasswordChange} />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirm}>Confirm</Button>
                    </DialogActions>
                </Dialog>

                
                <Stack spacing={2}>
                    <Paper ref={this.paperRef} variant="outlined" style={{overflow: 'auto'}} sx={{'@media (max-width:599px)': {height:"400px"}}}>
                        {table}
                    </Paper>
                    <Button variant="contained" onClick={this.handleSave}>Save</Button>
                </Stack>
                <Snackbar open={this.state.snackbarOpen} autoHideDuration={3000} onClose={() => this.setState({snackbarOpen: false})} message="Saved successfully" />
            </Container> 
        )
    }
}

const SharePage = props => {
    const params = useParams()
    const [searchParams] = useSearchParams()

    React.useEffect(() => {
        document.title = "Schedule - Share Access"
    });
  
    return <InnerSharePage params={params} searchParams={searchParams} {...props} />
  }

export default SharePage;