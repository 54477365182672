import React from "react";
import { Table } from "./Table";
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';

export class ActionTable extends Table {
	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
	}

	getIndexFromX(X) {
		return Math.floor(X / this.getWidth());
	}
	
	getIndexFromY(Y) {
		return Math.min(Math.max(0, Math.floor(Y / this.props.height - 1)), this.props.tableRows.length - 1);
	}

	getMouseY(event) {
		return event.pageY - this.tableRef.current.offsetTop - window.pageYOffset + this.props.parentRef.current.scrollTop;
	}

	getMouseX(event) {
		return event.pageX - this.tableRef.current.offsetLeft - window.pageXOffset + this.props.parentRef.current.scrollLeft - this.getWidth();
	}

	onMouseMove = (event) => {
		this.onMoveEventTriggerCount += 1;
		
		if (this.onMoveEventTriggerCount >= 5 && this.activeMouseEvent !== undefined) {
			this.activeMouseEvent(event);
		}
	}
    
	onMouseUp = (event) => {
		if (this.onMoveEventTriggerCount >= 5) {
			if (this.onClickEvent !== undefined) {
				this.onClickEvent(event);
			}
		} else {
			if (this.clickOnlyEvent !== undefined) {
				this.clickOnlyEvent(event);
			}
		}
		this.activeMouseEvent = undefined;
		this.onMoveEventTriggerCount = 0;
	}
    
	onMouseDown(initFn, moveFn, clickFn, clickOnlyFn) {
		return (event) => {
			event.stopPropagation(); 
			if (this.activeMouseEvent === undefined) {
				this.onMoveEventTriggerCount = 0;
				this.activeMouseEvent = moveFn;
				this.onClickEvent = clickFn;
				this.clickOnlyEvent = clickOnlyFn;
				initFn(event);
			}
		}
	}

	onRightMouseDown(initFn, moveFn, clickFn, clickOnlyFn) {
		return (event) => {
			if (event.button === 0) {
				this.onMouseDown(initFn, moveFn, clickFn, clickOnlyFn)(event);
			}
		}
	}

	onRightMouseUp = (event) => {
		if (event.button === 0) {
			this.onMouseUp(event);
		}
	}

	handleContextMenu = (event) => {
		event.preventDefault();
		this.setState({rightClickX: event.pageX, rightClickY: event.pageY});
	}
	
	handleClick = () => {
		this.setState({rightClickX: undefined, rightClickY: undefined});
	}

	componentDidMount() {
		super.componentDidMount();
		document.addEventListener("click", this.handleClick);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick);
	}

	additionalMenuItems() {
		return [];
	}

	copyAction = () => {
		let [data, selectAll, ] = this.returnData();
		this.setState({clipboard: [data, selectAll]});
	}

	pasteAction = () => {
		if (this.state.clipboard !== undefined) {
			this.loadData(...this.state.clipboard, true, this.state.clipboard)
		}
	}

	render(table) {
		let additionalMenuItems = this.additionalMenuItems();
		let menu = <Paper sx={{ width: 200, maxWidth: '60%', left: this.state.rightClickX, top: this.state.rightClickY, zIndex: 999, position: "absolute"}}>
			<MenuList>
				<MenuItem onClick={this.copyAction}>
					<ListItemIcon>
					<ContentCopy fontSize="small" />
					</ListItemIcon>
					<ListItemText>Copy</ListItemText>
				</MenuItem>
				<MenuItem onClick={this.pasteAction}>
					<ListItemIcon>
					<ContentPaste fontSize="small" />
					</ListItemIcon>
					<ListItemText>Paste</ListItemText>
				</MenuItem>
				{additionalMenuItems.length === 0 || <Divider /> }
				{additionalMenuItems.map((item) => item)}
			</MenuList>
		</Paper>;

		return <div onContextMenu={this.handleContextMenu}>
			{table}
			{this.state.rightClickX && this.state.rightClickY && menu}
		</div>
	}
}